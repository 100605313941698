import { CollectionCard } from './collection-card.tsx'
import type { EventInfo } from '~/model/api.ts'
import { EventInfoType } from '~/model/api.ts'
import { Badge } from '~/components/ui/badge.tsx'
import { useTranslation } from 'react-i18next'
import { Icon } from '~/components/icon.tsx'
import { RouteProfile } from './route-profile.tsx'
import { IconValue } from '~/components/ui/icon-value.tsx'
import { convertMetersToKm } from '~/utils/distance.ts'

export function EventInfoCard({
  title,
  coverPhoto,
  mainPhoto,
  type,
  official,
  route,
  displayStartDateTime,
  expand,
  description,
  registrationsCount,
  sessions,
  laps,
}: EventInfo & {
  official?: boolean
  displayStartDateTime?: string
  expand?: boolean
}) {
  const { t } = useTranslation()

  const url = `${ENV.RIDERS_PORTAL_URL}/events/${sessions[0]?.id}`

  const badges = (
    <>
      {official && (
        <Badge className="uppercase" color="yellow">
          <Icon name="crown" className="h-4 w-4" />
          {t('event_official_label')}
        </Badge>
      )}
      {type && (
        <Badge className="uppercase" size="small">
          {type === EventInfoType.Race && t('event_type_race_label')}
          {type === EventInfoType.GroupRide && t('event_type_group_ride_label')}
        </Badge>
      )}
    </>
  )

  const properties = (
    <>
      <IconValue
        icon={<Icon name="distance" className="h-4 w-4" />}
        value={`${convertMetersToKm(route.distanceMeters * laps)} km`}
      />
      <IconValue
        icon={<Icon name="elevation" className="h-4 w-4" />}
        value={`${route.ascendedMeters * laps} m`}
      />
      <IconValue
        icon={<Icon name="cyclist" className="h-4 w-4" />}
        value={registrationsCount as unknown as string}
      />
      <IconValue
        icon={<Icon name="coin" className="h-4 w-4" />}
        value={sessions[0]?.coinsForCompletion.toString()}
        className="text-global-jonquil-yellow-700"
      />
    </>
  )

  return (
    <CollectionCard
      title={title}
      link={url}
      bgImageUrl={
        coverPhoto?.data?.attributes?.url ??
        mainPhoto?.data?.attributes?.url ??
        route.thumbnails.large
      }
      badges={badges}
      geometry={
        <RouteProfile
          geometry={route.simplifiedGeometryPoints!.map(point => ({
            altitude: point!.altitude!,
            distance: point!.distance!,
          }))}
          height={32}
          strokeWidth={3}
        />
      }
      properties={properties}
      subtitle={
        <span className="text-body-12">
          {t('Starts')}&nbsp;
          {displayStartDateTime}
        </span>
      }
      expand={expand}
      expandedTitle={title}
      expandedDescription={description ?? undefined}
    />
  )
}
